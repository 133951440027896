import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-see-more',
    templateUrl: './see-more.component.html'
})
export class SeeMoreComponent implements OnInit{

    @Input() title = 'Service Description';
    @Input() msg = 'N.A.';
    @Input()  image = 'N.A.';

    modalData :any ;

    constructor(
        public bsModalRef: BsModalRef,
        private modalService: BsModalService,
    ) {
    }
   
    ngOnInit(): void {
       if(this.modalData){
           console.log(this.modalData ,"dsvgvguhbsdi") ;
       } 
    }
}

